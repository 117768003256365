<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-18 15:19:05
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-02-21 09:30:55
-->
<template>
  <div class="container-warp">
    <div class="img-box">
      <img src="@/assets/img/img_Arrange.png" alt="" />
    </div>
    <div class="info-box">
      <div class="info-box-title">{{currentExamInfo.examName}}</div>
      <div class="info-box-time">考试时间：{{currentExamInfo.startTime}}-{{currentExamInfo.endTime}}</div>
      <!-- <div class="info-box-score">满分{{currentExamInfo.sumScore}}分</div> -->
      <div class="info-box-detail">
        <div class="info-box-detail-item">
          <div>考试题目</div>
          <div>{{currentExamInfo.sumQuestionNum||'—'}}道</div>
        </div>
        <div class="line-box"></div>
        <div class="info-box-detail-item">
          <div>考试时长</div>
          <div>{{currentExamInfo.duration||'—'}}分钟</div>
        </div>
        <div class="line-box"></div>
        <div class="info-box-detail-item">
          <div>考试总分</div>
          <div>{{currentExamInfo.sumScore||'—'}}分</div>
        </div>
      </div>
      <el-button class="start-btn-box" type="primary" @click="_startAnswer" :disabled="!_isInRange">开始答题</el-button>
      <div class="hint-box" v-if="!_isInRange">未到考试时间</div>
    </div>
  </div>
</template>

<script>
import { getPersonnalExamListApi } from '@/api/examApi'
import { getCurrentTime } from '@/utils/util'
export default {
  data () {
    return {
      currentExamList: [],
      currentExamInfo: {}

    }
  },
  computed: {
    _isInRange () { //  判断当前是否在考试时间内
      const tempExamInfo = JSON.parse(localStorage.getItem('wholeExamTimeInfos'))
      const start = new Date(tempExamInfo.startTime).getTime()
      const end = new Date(tempExamInfo.endTime).getTime()
      const now = new Date(getCurrentTime()).getTime()
      return now >= start && now <= end
    }
  },
  created () {
    console.log(this.$route.query.examInfoId)
    if (this.$route.query.examInfoId) {
      this._getPersonnalExamList()
    }
  },
  mounted () {

  },
  methods: {
    _getPersonnalExamList () {
      getPersonnalExamListApi().then((res) => {
        if (res.code === 200) {
          const personExamList = res.data
          this.currentExamList = personExamList.filter((item) => Number(item.examInfoId) === Number(this.$route.query.examInfoId))
          this.currentExamInfo = this.currentExamList[0]
        }
      })
    },
    // 开始答题
    _startAnswer () {
      localStorage.setItem('startAnswerQuestionTime', getCurrentTime())
      this.$router.push({
        path: '/examDetails',
        query: {
          examInfoId: this.$route.query.examInfoId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-warp {
  min-height: 800px;
  background-color: #ffffff;
  border-radius: 4px;
  .img-box {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .info-box-title {
      margin-top: 32px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }
    .info-box-time {
      margin-top: 8px;
      font-size: 16px;
      color: #0089ff;
    }
    .info-box-score {
      margin-top: 16px;
      width: 98px;
      font-size: 14px;
      line-height: 28px;
      border-radius: 14px;
      background-color: #ecf5ff;
      color: #0089ff;
      text-align: center;
    }
    .info-box-detail {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 480px;
      height: 92px;
      background: linear-gradient(to bottom, #f0f8ff, #ffffff);
      border-radius: 8px;
      border: 1px solid #e7f4ff;
      .line-box {
        width: 1px;
        height: 38px;
        background-color: #d2ebff;
      }
      .info-box-detail-item {
        div {
          &:first-child {
            margin-bottom: 8px;
            font-size: 14px;
            color: #4d4d4d;
          }
          &:last-child {
            font-size: 18px;
            font-weight: 600;
            color: #333333;
          }
        }
      }
    }
    /deep/ .start-btn-box.el-button {
      margin-top: 36px;
      width: 480px;
      height: 48px;
      font-size: 16px;
    }
    .hint-box {
      margin-top: 8px;
      font-size: 12px;
      color: #666666;
    }
  }
}
</style>
